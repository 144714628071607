import { isSupported, MemoryStorage } from 'local-storage-fallback';

['localStorage', 'sessionStorage'].forEach((key) => {
  if (!isSupported(key))
    Object.defineProperty(window, key, {
      value: new MemoryStorage(),
      configurable: true,
      enumerable: true
    });
});
